const ApiEndpoints = {
  LOGIN: "auth/login",
  SOCIAL_CONFLICTS_LIST: "socialConflicts/list",
  SOCIAL_CONFLICTS_DETAIL: "socialConflicts/detail",
  SOCIAL_CONFLICTS_ADD: "socialConflicts/add",
  SOCIAL_CONFLICTS_EDIT: "socialConflicts/edit",
  SOCIAL_CONFLICTS_REMOVE: "socialConflicts/remove",
  CATEGORIES_LIST: "categories/list",
  CATEGORIES_DETAIL: "categories/detail",
  CATEGORIES_ADD: "categories/add",
  CATEGORIES_EDIT: "categories/edit",
  ARTICLES_GET_LATEST: "articles/getLatest",
  ARTICLES_GET_FEATURED: "articles/getFeatured",
  ARTICLES_GET_DETAILS: "articles/getDetails",
  USERS_LIST: "users/list",
  USERS_DETAIL: "users/detail",
  USERS_ADD: "users/add",
  USERS_EDIT: "users/edit",
  UNIONS_LIST: "unions/list",
  UNIONS_DETAIL: "unions/detail",
  UNIONS_ADD: "unions/add",
  UNIONS_EDIT: "unions/edit",
  EXPORT_ACTIONS: "export/actions",
};
export default ApiEndpoints;
