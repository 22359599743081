import React from "react";
import { SocialConflict } from "../../shared/SocialConflict";
import { Popup } from "react-map-gl";
import "../../styles/MapPopup.scss";

import "../../styles/ArticleMini.scss";
import { motivesLabelsEnum } from "../../shared/motives";
import { actionTypeLabelsEnum } from "../../shared/actionType";
import moment from "moment";
import { DATE_FORMAT } from "../../shared/config";
import { Col, Row, Spin } from "antd";
import useArticleDetails from "../../shared/hooks/useArticleDetails";

interface MapPopup {
  socialConflict?: SocialConflict;
  onClose: () => void;
}

const MapPopup: React.FunctionComponent<MapPopup> = ({
  socialConflict,
  onClose,
}) => {
  const lidArticleLinks: string[] = [];
  socialConflict &&
    socialConflict.actions.forEach((action) => {
      if (action?.link1 && action.link1.includes("laizquierdadiario.com")) {
        lidArticleLinks.push(action.link1);
      }
      if (action?.link2 && action.link2.includes("laizquierdadiario.com")) {
        lidArticleLinks.push(action.link2);
      }
    });

  const { articleDetails, isLoadingArticleDetails } = useArticleDetails({
    links: lidArticleLinks,
  });

  if (!socialConflict) {
    return null;
  }

  return (
    <Popup
      tipSize={5}
      anchor="top"
      longitude={socialConflict.location.coords.lng}
      latitude={socialConflict.location.coords.lat}
      closeOnClick={false}
      onClose={() => onClose()}
      className="map-popup"
      dynamicPosition={false}
    >
      <div className="map-popup__inner">
        <h3 className="map-popup__title">{socialConflict.title}</h3>
        <p>
          Lugar: {socialConflict.location.description}
          <br />
        </p>
        <p>
          Demanda principal: {motivesLabelsEnum[socialConflict.mainDemand]}
          {socialConflict.otherDemands?.length > 0 && (
            <>
              <br />
              <span>Otras demandas: </span>
              {socialConflict.otherDemands.map((demandCode) => (
                <span>{motivesLabelsEnum[demandCode]} </span>
              ))}
            </>
          )}
        </p>

        <p>Acciones tomadas:</p>
        <ul>
          {socialConflict.actions.map((action) => {
            return (
              <li key={JSON.stringify(action)}>
                <p>
                  <span>Fecha: {moment(action.date).format(DATE_FORMAT)}</span>
                  <br />
                  <span>
                    Tipo:{" "}
                    {action.actionTypeIds &&
                      action.actionTypeIds?.length > 0 &&
                      action.actionTypeIds
                        .map(
                          (actionTypeId) => actionTypeLabelsEnum[actionTypeId]
                        )
                        .join(" - ")}
                  </span>
                  <br />
                  {action.actionParticipants && (
                    <span>Participantes: {action.actionParticipants}</span>
                  )}
                  <br />
                  {action.actionDuration && (
                    <span>Duración: {action.actionDuration}</span>
                  )}
                </p>
              </li>
            );
          })}
        </ul>

        {isLoadingArticleDetails && <Spin />}
        {!isLoadingArticleDetails && articleDetails.length > 0 && (
          <div className="map-popup__related-articles-list">
            {articleDetails.map((article, i) => (
              <a href={article.link} target="_blank" rel="noopener noreferrer">
                <Row className="article-mini" key={article.id} gutter={10}>
                  <Col span={6}>
                    <img
                      className="article-mini__img"
                      src={article.miniatura.replace("http:", "https:")}
                      alt=""
                    />
                  </Col>
                  <Col span={18}>
                    <h3 className="article-mini__title">{article.titulo}</h3>
                    <p className="article-mini__description">
                      [+] Leer la nota
                    </p>
                  </Col>
                </Row>
              </a>
            ))}
          </div>
        )}
      </div>
    </Popup>
  );
};
export default MapPopup;
