import React, { useState } from "react";
import { Button, message } from "antd";
import moment from "moment";
import ApiEndpoints from "../../../shared/ApiEndpoints";
import FetchService from "../../../shared/fetchService";

const AdminExport = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadExport = async (endpoint: string, fileName: string) => {
    let closeLoading;
    try {
      closeLoading = message.loading("Generando archivo", 0);
      setIsDownloading(true);
      const result = await FetchService.request(endpoint, {
        body: JSON.stringify({}),
      });
      let csvContent = `data:text/csv;charset=utf-8,${result.csv}`;
      var encodedUri = encodeURI(csvContent);

      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${fileName}-${moment().format("DD-MM-YYYY--HH-MM")}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    } finally {
      setIsDownloading(false);
      closeLoading && closeLoading();
    }
  };

  return (
    <div>
      <Button
        type="primary"
        style={{ marginRight: 10 }}
        disabled={isDownloading}
        onClick={() => {
          downloadExport(ApiEndpoints.EXPORT_ACTIONS, "conflictos");
        }}
      >
        Exportar Acciones
      </Button>
    </div>
  );
};
export default AdminExport;
