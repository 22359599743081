import React from "react";

import "../styles/NewsList.scss";
import useFeaturedArticles from "../shared/hooks/useFeaturedArticles";
import { Skeleton } from "antd";
import ArticlePreview from "./ArticlePreview";

const NewsList: React.FunctionComponent = () => {
  const { articles, isLoadingArticles } = useFeaturedArticles();

  return (
    <div className="news-list">
      <span className="news-list__title">Últimas Noticias</span>
      {!isLoadingArticles ? (
        articles
          .filter((article) => article.imageSrc)
          .slice(0, 5)
          .map((article) => (
            <ArticlePreview key={article.id} article={article} />
          ))
      ) : (
        <div style={{ opacity: 0.6 }}>
          <Skeleton active />
        </div>
      )}
    </div>
  );
};
export default NewsList;
