import React from "react";

import "../styles/SocialConflictsStats.scss";
import { Skeleton } from "antd";
import { orderBy } from "lodash";
import PercentageStats from "./PercentageStat";
import { SocialConflictList } from "../shared/hooks/useSocialConflicts";
import { motivesEnum, motivesLabelsEnum } from "../shared/motives";

interface SocialConflictsStatsProps {
  socialConflicts: SocialConflictList;
}

interface CategoryStat {
  id: string;
  title: string;
  count: number;
}

const getCategoriesStats = ({
  socialConflicts,
}: {
  socialConflicts: SocialConflictList;
}) => {
  const motiveStats: CategoryStat[] = Object.values(motivesEnum).map(
    (motiveCode) => ({
      id: motiveCode,
      title: motivesLabelsEnum[motiveCode],
      count: 0,
    })
  );
  socialConflicts.forEach((socialConflict) => {
    const demands: string[] = [socialConflict.mainDemand];
    if (socialConflict.otherDemands) {
      demands.push(...socialConflict.otherDemands);
    }

    demands.forEach((motiveCode) => {
      const motiveStat = motiveStats.find((c) => c.id === motiveCode);
      if (motiveStat) {
        motiveStat.count += 1;
      }
    });
  });
  console.log("socialConflicts", socialConflicts);
  console.log("motiveStats", motiveStats);

  return orderBy(
    motiveStats.filter((c) => c.count),
    "count",
    "desc"
  );
};

const SocialConflictsStats: React.FunctionComponent<SocialConflictsStatsProps> = ({
  socialConflicts,
}) => {
  if (!socialConflicts.length) {
    return (
      <div style={{ opacity: 0.6 }}>
        <Skeleton active />
      </div>
    );
  }

  const total = socialConflicts.length;

  const categoriesStats = getCategoriesStats({ socialConflicts });

  return (
    <div className="social-conflict-stats">
      <span className="social-conflict-stats__main-number">{total}</span>
      <span className="social-conflict-stats__main-label">conflictos</span>

      <hr className="separator" />

      <div>
        {categoriesStats.map(({ id, count, title }) => (
          <PercentageStats
            key={id}
            title={title}
            number={count}
            total={total}
          />
        ))}
      </div>
    </div>
  );
};
export default SocialConflictsStats;
