import React from "react";
import { Article } from "../shared/Article";

import "../styles/ArticlePreview.scss";

interface ArticlePreviewProps {
  article: Article;
}

const ArticlePreview: React.FunctionComponent<ArticlePreviewProps> = ({
  article,
}) => {
  return (
    <div className="article-preview">
      <div className="article-preview__image">
        <a href={article.link} rel="noopener noreferrer" target="_blank">
          <img className="article-preview__img" src={article.imageSrc} alt="" />
        </a>
      </div>
      <a
        href={article.link}
        rel="noopener noreferrer"
        target="_blank"
        className="article-preview__title"
      >
        {article.title}
      </a>
    </div>
  );
};
export default ArticlePreview;
