import React from "react";
import "antd/dist/antd.css";
import "./styles/App.scss";
import Routes from "./Routes";

function App() {
  return <Routes />;
}

export default App;
