import React from "react";

import "../styles/PercentageStat.scss";

interface PercentageStats {
  title: string;
  number: number;
  total: number;
}

const PercentageStats: React.FunctionComponent<PercentageStats> = ({
  title,
  number,
  total,
}) => {
  return (
    <div className="percentage-stat">
      <div className="percentage-stat__labels">
        <span className="percentage-stat__title" title={title}>
          {title}
        </span>
        <span className="percentage-stat__number">{number}</span>
      </div>
      <div className="percentage-stat__bar">
        <span
          className="percentage-stat__bar-inner"
          style={{ width: `${((number / total) * 100).toFixed(2)}%` }}
        />
      </div>
    </div>
  );
};
export default PercentageStats;
